import ClipLoader from 'react-spinners/ClipLoader';

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'black',
  marginTop: '220px'
};

export const LoadingScreen = () => {
  return (
    <>
      <ClipLoader
        color={'black'}
        loading={true}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};
