import { extendTheme, theme } from '@chakra-ui/react';

// Global style overrides
import styles from './overrideStyles';
import colors from './colors';
// sample structure from charka webiste:
// Foundational style overrides
// import borders from './foundations/borders';
// Component style overrides
// import Button from './components/button';
console.log(theme);

const overrides = {
  styles,
  colors,
  components: {
    Heading: {
      fontFamily: 'PT Serif'
    },
    Link: {
      baseStyle: {
        // transition: 'color 1000ms linear',
        // color: theme.colors.teal['500'],
        // font
        color: '#323232',
        textDecoration: 'none',
        _hover: {
          textDecoration: 'none'
          //   color: 'blue'
        }
      }
    },
    Button: {
      baseStyle: {
        // transition: 'color 1000ms linear',
        color: theme.colors.red['500'],
        // color: '#323232',
        color: theme.colors.blackAlpha['500'],
        color: theme.colors.gray,
        // background: theme.colors.blackAlpha['500']
        // background: 'red',
        // backgroundColor: 'red',
        textDecoration: 'none',
        _hover: {
          textDecoration: 'none'
          //   color: 'blue'
        }
      }
    }
  },
  //   borders
  // Other foundational style overrides go here
  //   components: {
  //     Button
  //     // Other components go here
  //   }
  fonts: {
    heading: `Anek Devanagari`,
    body: `Anek Devanagari`
  }
};

// font-family: "Anek Devanagari", sans-serif;
// font-optical-sizing: auto;
// font-weight: <weight>;
// font-style: normal;
// font-variation-settings:
//   "wdth" 100;
export default extendTheme(overrides);
