import { getJWT } from './token';

// SHould come from a config file
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    const item = parts.pop();
    if (item) {
      return item.split(';').shift();
    }
  }
}

export const request = (options) => {
  const { path, method, body, params, files, publicPath } = Object.assign(
    {
      method: 'GET'
    },
    options
  );

  //STUB API

  if (path === '/meremovestub') {
    return Promise.resolve({
      id: '1',
      name: 'Chronicle user',
      email: `amanji75@gmail.com `
    });
  }

  const csrftoken = getCookie('csrftoken');
  //   xhr.setRequestHeader("X-CSRFToken", csrftoken);

  const headers = Object.assign(
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer facke.toxet.thel`,
      'X-CSRFToken': csrftoken
      // 'Access-Control-Allow-Origin': '*'
      //'show-es-query': 'true'
    },
    options.headers || {}
  );

  const paramsPath = Object.keys(params || {}).length
    ? `?${qsStringify(params)}`
    : '';

  let endpoint = `${API_URL.replace(/\/$/, '')}/${path.replace(
    /^\//,
    ''
  )}${paramsPath}`;

  if (path.includes('https://') || path.includes('http://')) {
    endpoint = `${path}${paramsPath}`;
  }

  if (publicPath) {
    endpoint = path;
  }
  // const endpoint = `${API_URL.replace(/\/$/, '')}/${path.replace(
  //   /^\//,
  //   ''
  // )}${paramsPath}`;
  if (getJWT()) headers.Authorization = `Bearer ${getJWT()}`;
  let promise;
  if (method.toUpperCase() === 'GET') {
    promise = fetch(endpoint, { headers });
  } else {
    if (files) {
      const formData = new FormData();
      Object.keys(files).forEach((fileKey) => {
        formData.append('files[]', files[fileKey], files[fileKey].name);
      });
      Object.keys(body).map((field) => {
        return formData.append(field, body[field]);
      });
      delete headers['Content-Type'];
      promise = fetch(endpoint, {
        method,
        headers,
        body: formData
      });
    } else {
      promise = fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify(body)
      });
    }
  }

  return promise.then((res) => {
    if (res.status === 204) return;
    if (
      ['text/csv', 'application/pdf'].includes(
        res.headers.get('Content-type')
      ) &&
      res.headers.get('Content-Disposition').includes('filename=')
    ) {
      return res.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;

        const filename = res.headers
          .get('Content-Disposition')
          .split(';')[1]
          .replace('filename=', '');

        a.download = filename.trim();
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        return;
      });
    }

    if (!res.ok) {
      return res.text().then((data) => {
        let d;
        try {
          d = JSON.parse(data);
        } catch (e) {
          d = {};
        }
        const err = new Error(d.message || res.statusText);
        err.status = res.status;
        if (d.code) err.code = d.code;
        if (d.details) {
          err.details = {};
          d.details.forEach((details) => {
            err.details[details.path.join('.')] = details;
          });
        }
        throw err;
      });
    }
    return [200, 201].includes(res.status) ? res.json() : undefined;
  });
};

export const qsStringify = (obj) => {
  return obj
    ? Object.keys(obj)
        .map((key) => {
          const val = obj[key];
          if (Array.isArray(val)) {
            return val
              .map((val2) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(val2)}`;
              })
              .join('&');
          }

          return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        })
        .join('&')
    : '';
};
