import React from 'react';
import { request } from 'utils/api';
import { getJWT } from 'utils/token';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'context';
import { LoadingScreen } from 'Components/LoadingScreen';

const ProtectedRoute = () => {
  const { user, updateUser, logout } = useAuth();

  React.useEffect(() => {
    const checkUser = async () => {
      if (user && user.id) {
        return;
      }

      if (!getJWT()) return;
      // when backend works, make a request here to ensure token is valid and that the
      // user/s account hasn't expired. The JWT is automatically included in every request
      // NOTE, with the router setup, the app may make this call every time
      // the user changes pages, or since they're nested, it may automaticall handle it.
      // If it becomes an issue, fetch data with React Query
      // (https://github.com/TanStack/query) so that results are cached.
      try {
        const data = await request({
          method: 'GET',
          path: '/users/me'
        });

        updateUser(data);
      } catch (err) {
        logout();
      }
    };
    checkUser();
  }, []);

  // Check JWT exists
  if (!getJWT()) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  if (!user || user.id === '') {
    return <LoadingScreen />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};

export default ProtectedRoute;
