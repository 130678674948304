import * as React from 'react';
import { request } from 'utils/api';
import { setJWT, getJWT } from 'utils/token';

// type User = {
//   id: string;
//   name: string;
//   email: string;
// };

// interface AuthContextInterface {
//   token: string | null;
//   user?: User;
//   login: (email: string, password: string) => void;
//   loginWithToken: (token: string) => void;
//   logout: () => void;
//   loggedIn: boolean;
//   isReady: boolean;
//   updateUser: (user: User) => void;
// }

// const AuthContext = React.createContext({} as AuthContextInterface);
const AuthContext = React.createContext({});

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const [user, setUser] = React.useState();
  const [jwt, Setjwt] = React.useState(getJWT());

  const login = async (email, password) => {
    try {
      const resp = await request({
        method: 'POST',
        body: { email, password },
        path: '/login'
      });
      setLoggedIn(true);
      setUser(resp.data);
      setJWT(resp.data.token);
    } catch (err) {
      setLoggedIn(false);
      // throw err;
    }
  };

  const loginWithToken = async (token) => {
    const resp = await request({
      method: 'POST',
      body: { token },
      path: '/login'
    });
    setUser(resp.data);
    setLoggedIn(true);
    setIsReady(true);
  };

  const logout = async () => {
    setJWT(undefined);
    setUser(undefined);
    setIsReady(true);
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        loggedIn,
        user,
        loginWithToken,
        isReady,
        updateUser: (data) => setUser(data),
        token: jwt
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// export function useAuth() {
//   const context = React.useContext(AuthContext);
//   // const context = React.useContext(TestContext);
//   debugger;
//   //   if (context === undefined) {
//   //     debugger;
//   //     throw new Error('useAuth must be used within a AutoProvider Context');
//   //   }
//   //   debugger;
//   return context;
// }

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AutoProvider Context');
  }
  return context;
};

// export { AuthProvider };
export { AuthProvider, useAuth };
// export { AuthProvider, AuthContext };
