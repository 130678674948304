export default {
  global: {
    body: {
      //   fontFamily: 'papyrus'
      //   lineHeight: 'base'

      fontFamily: 'Anek Devanagari'
    },
    h2: {
      fontFamily: 'Anek Devanagari'
    }
  }
};
