import theme from 'Theme';

import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'Router';
import AppProviders from 'context';
import { LoadingScreen } from 'Components/LoadingScreen';

import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <AppProviders>
      <ChakraProvider theme={theme}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Suspense fallback={<LoadingScreen />}>
            <RouterProvider
              router={router}
              fallbackElement={<p>Initial Load...</p>}
            />
          </Suspense>
        </GoogleOAuthProvider>
      </ChakraProvider>
    </AppProviders>
  );
}
export default App;
