import * as React from 'react';

const NotificationsContext = React.createContext({});

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = React.useState([]);
  const [longLoading, setLongLoading] = React.useState({ spinner: 'circle' });

  return (
    <NotificationsContext.Provider
      value={{
        addNotification: ({ type, text, duration = 2000, id = Date.now() }) => {
          notifications.push({
            type,
            text,
            id,
            visible: true
          });

          setTimeout(() => {
            notifications.forEach((notification, index) => {
              if (notification.id === id) {
                notification.visible = false;
              }
            });
            setNotifications(notifications.slice());
          }, duration);
        },
        notifications,
        longLoading,
        setLongLoading
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => {
  const context = React.useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used AutoProvider Context');
  }
  return context;
};

export { NotificationsProvider, useNotifications };
