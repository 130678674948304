import * as React from 'react';
import { AuthProvider, useAuth } from './auth';
import { BrandProvider } from 'context/brandContext';
import { CurrentNewsletterProvider } from 'context/currentNewsletterContext';
import { NotificationsProvider } from 'context/notificationsContext';
import { useBrand } from 'context/brandContext';
import { useCurrent } from 'context/currentNewsletterContext';
import { useNotifications } from 'context/notificationsContext';

const App = ({ children }: any) => {
  const { user }: any = useAuth();
  if (user && user.id) {
    return (
      <BrandProvider>
        <CurrentNewsletterProvider>{children}</CurrentNewsletterProvider>
      </BrandProvider>
    );
  } else {
    return children;
  }
};

const AppProviders = ({ children }: any) => {
  return (
    <AuthProvider>
      <NotificationsProvider>
        <App>{children}</App>
      </NotificationsProvider>
    </AuthProvider>
  );
};

export default AppProviders;

export { useAuth, useBrand, useCurrent, useNotifications };
