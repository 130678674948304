const CHRONICLE_TOKEN = 'chronicleToken';

export function getJWT() {
  return localStorage.getItem(CHRONICLE_TOKEN);
}

export function setJWT(token) {
  if (!token) {
    localStorage.removeItem(CHRONICLE_TOKEN);
  } else {
    localStorage.setItem(CHRONICLE_TOKEN, token);
  }
}
