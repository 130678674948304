import { request } from 'utils/api';

const checkEmailAvailability = async (email) => {
  try {
    const resp = await request({
      method: 'POST',
      body: { email },
      path: '/users/email_available/'
    });

    return resp.email_available;
  } catch (err) {
    return err;
  }
};

const validationMethods = {
  required: async (field, value, errorObj) => {
    if (!value) {
      errorObj[field] = 'Required';
      return false;
    }

    return true;
  },
  emailAvailable: async (field, value, errorObj) => {
    const emailAvailable = await checkEmailAvailability(value);
    if (!emailAvailable) {
      errorObj[field] = 'Email Unavailable';
      return false;
    }

    return true;
  },
  promptLength: (field, value, errorObj) => {
    if (value.length > 0 && value.length < 100) {
      errorObj[field] =
        'The prompt needs to be at least 100 characters to create a quality newsletter.';
      return false;
    }

    return true;
  }
};

export const validateForm = async ({ model, error, validations }) => {
  await Promise.all(
    Object.keys(validations).map(async (field) => {
      delete error[field];
      return await validateInput({
        field,
        value: model[field],
        error,
        validations
      });
    })
  );

  return Object.keys(error).length;
};

export const validateInput = async ({ field, value, error, validations }) => {
  const err = await Promise.all(
    validations[field].map(async (method) => {
      return await validationMethods[method](field, value, error);
    })
  );

  return Object.keys(error).length;
};
