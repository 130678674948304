import { useContext, createContext, useEffect, useState } from 'react';
import { request } from 'utils/api';

const BrandContext = createContext({});

const BrandProvider = ({ children }) => {
  const statusChecker = async (setBrandingStatus) => {
    const resp = await request({
      method: 'GET',
      path: '/branding'
    });
    setBrandingStatus({
      status: resp.status,
      nextStage: resp.next_stage
    });
    if (resp.status !== 'complete') {
      setTimeout(() => {
        statusChecker(setBrandingStatus);
      }, 6000);
    }
  };
  const [brandingStatus, setBrandingStatus] = useState({
    status: 'processing',
    nextStage: 'recieving'
  });
  const [pastNewsletters, setPastNewsletters] = useState([]);
  const [events, setEvents] = useState([]);
  const [firstNewsletterComplete, setFirstNewsletterComplete] = useState(true);

  const [brand, setBrand] = useState({
    events: [],
    status: 'complete'
  });

  const updateBrand = (data) => {
    setBrand({
      ...brand,
      ...data
    });
  };

  const addEvents = (data) => {
    let newEvents = data.holidays.map((holiday) => {
      return {
        country: holiday.country.name,
        date: new Date(holiday.date.iso),
        name: holiday.name[0].text,
        description: holiday.oneliner[0].text,
        type: holiday.types[0],
        subtype: holiday.subtype
      };
    });

    const today = new Date();
    newEvents = newEvents.filter((event) => {
      return event.date >= today;
    });
    newEvents.sort((a, b) => {
      return a.date - b.date;
    });
    setEvents(newEvents);
  };

  useEffect(() => {
    const loadData = async () => {
      const eventData = await request({
        method: 'GET',
        path: '/2024events.json',
        publicPath: true
      });
      addEvents(eventData);

      const letters = await request({
        method: 'GET',
        path: '/newsletters/'
      });
      setPastNewsletters(letters);
      if (!letters.length) {
        setFirstNewsletterComplete(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      request({
        method: 'GET',
        path: '/branding'
      }).then((resp) => {
        if (resp.status !== 'complete') {
          statusChecker(setBrandingStatus);
        }
        setBrandingStatus({
          status: resp.status,
          nextStage: resp.next_stage
        });
        updateBrand(resp);
      });
    };

    loadData();
  }, [setBrandingStatus, setBrand]);

  return (
    <BrandContext.Provider
      value={{
        brandingStatus,
        brand,
        updateBrand,
        firstNewsletterComplete,
        setFirstNewsletterComplete,
        addPastNewsletters: (data) => {
          setBrand({
            ...brand,
            pastNewsletters: data
          });
        },
        pastNewsletters,
        setPastNewsletters,
        events,
        addEvents
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

const useBrand = () => {
  const context = useContext(BrandContext);
  if (context === undefined) {
    throw new Error('useBrand must be used within a AutoProvider Context');
  }
  return context;
};

export { BrandProvider, useBrand };
