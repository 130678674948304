import { createContext, useContext, useEffect, useState } from 'react';
import { validateForm } from 'utils/formValidator';
import { request } from 'utils/api';
import { useNotifications, useBrand } from 'context';
const CurrentNewsletterContext = createContext({});

const defaultNewsletter = {
  date: new Date(),
  // src: '/sampletemplate.html',
  title: '',
  prompt: '',
  error: {}
};

const CurrentNewsletterProvider = ({ children }) => {
  const { brand, brandingStatus, setFirstNewsletterComplete } = useBrand();
  const { addNotification, setLongLoading } = useNotifications();
  const [newsletter, setCurrentNewsletter] = useState(defaultNewsletter);
  const validateNewsletter = async () => {
    const validations = {
      title: ['required'],
      prompt: ['required', 'promptLength']
    };

    const hasError = await validateForm({
      model: newsletter,
      error: newsletter.error,
      validations
    });

    if (hasError) {
      setCurrentNewsletter({
        ...newsletter
      });
      return false;
    }
    return true;
  };

  const createNewsletter = async (saveCallback) => {
    const { title, date, prompt, images, error, status } = newsletter;

    const creationStatus = async (id) => {
      const respStatus = await request({
        method: 'GET',
        path: `/newsletters/${id}`
      });

      if (!!respStatus.content) {
        addNotification({
          type: 'success',
          text: 'Newsletter Created'
        });

        setFirstNewsletterComplete(true);

        setCurrentNewsletter({
          ...newsletter,
          creating: false,
          content: respStatus.content
        });

        saveCallback(`/newsletters/${id}`);
      } else {
        setTimeout(() => {
          creationStatus(id);
        }, 5000);
      }
    };

    const isValid = await validateNewsletter();
    if (!isValid) {
      return;
    }

    try {
      setCurrentNewsletter({
        ...newsletter,
        creating: true
      });
      const resp = await request({
        method: 'POST',
        path: `/newsletters/`,
        body: {
          title,
          date,
          content: prompt,
          subject: 'dummy subject'
        },
        files: images
      });

      setTimeout(() => {
        creationStatus(resp.id);
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (brandingStatus.status === 'complete') {
      createNewsletter();
    }
  }, [brandingStatus]);

  return (
    <CurrentNewsletterContext.Provider
      value={{
        newsletter,
        loadNewsletter: async (id) => {
          setLongLoading({
            loading: true
          });
          setCurrentNewsletter({
            id,
            loaded: false,
            error: {}
          });
          try {
            const resp = await request({
              method: 'GET',
              path: `/newsletters/${id}`
            });
            setLongLoading({
              loading: false
            });
            setCurrentNewsletter({
              ...resp,
              loaded: true,
              error: {}
            });
          } catch (err) {
            // setLoggedIn(false);
            // throw err;
          }
        },
        validateNewsletter,
        updateNewsletter: (data) => {
          setCurrentNewsletter({
            ...newsletter,
            ...data
          });
        },
        clearError: () => {
          newsletter.error = {};
          setCurrentNewsletter({
            ...newsletter
          });
        },
        createNewsletter
      }}
    >
      {children}
    </CurrentNewsletterContext.Provider>
  );
};

const useCurrent = () => {
  const context = useContext(CurrentNewsletterContext);
  if (context === undefined) {
    throw new Error('useCurrent must be used within a AutoProvider Context');
  }
  return context;
};

export { CurrentNewsletterProvider, useCurrent };
