// import type { LoaderFunctionArgs } from 'react-router-dom';
import React from 'react';
import {
  Form,
  Link,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
  useActionData,
  useFetcher,
  useLocation,
  useNavigation,
  useRouteLoaderData
} from 'react-router-dom';
import { getJWT } from 'utils/token';
import ProtectedRoute from 'Components/Routes/ProtectedRoute';

// import { fakeAuthProvider } from './auth';
const LoginPage = React.lazy(() => import('./Screens/Auth/Login'));
const Logout = React.lazy(() => import('./Screens/Auth/Logout'));
const Dashboard = React.lazy(() => import('./Screens/Dashboard'));
const Billing = React.lazy(() => import('./Screens/Billing'));
const PastList = React.lazy(() => import('./Screens/PastList'));
const NewsletterDetail = React.lazy(() => import('./Screens/NewsletterDetail'));
const Template = React.lazy(() => import('./Screens/Template'));
const Team = React.lazy(() => import('./Screens/Team'));
const UserProfile = React.lazy(() => import('./Screens/User'));

// ie routes that require a user to be signed in
const protectedRoutes = [
  {
    path: '/',
    element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
    children: [
      {
        path: '/',
        element: <Dashboard />
        // used to fetch data while page loads; probably won't need, but just in case,
        // more on it here https://dev.to/shaancodes/a-brief-intro-about-loaders-in-react-router-54d
        // loader() {
        //   // Our root route always provides the user, if logged in
        //   return { user: fakeAuthProvider.username };
        // },
      },
      // {
      //   path: '/users/:id',
      //   element: <UserProfile />
      // },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '/billing',
        element: <Billing />
      },
      {
        path: '/template',
        element: <Template />
      },
      {
        path: '/team',
        element: <Team />
      },
      {
        path: '/pastnewsletters',
        element: <PastList />
      },
      {
        path: '/newsletters/:slug',
        element: <NewsletterDetail />
      }
    ]
  }
];

// Define routes accessible only to non-authenticated users
const unprotectedRoutes = [
  {
    path: '/login',
    element: <LoginPage />
  }
];

const router = createBrowserRouter([
  ...protectedRoutes,
  ...unprotectedRoutes
  // ...(!getJWT() ? routesForOnlyLoggedOutUsers : [])
]);

export default router;

// const router = createBrowserRouter([]);
